import type { MenuLinkItem } from "~/types";
import { JUSYOU_REKI } from "~/constants/contest";
import { prefToAlphabet } from "~/utils/sekou/area";

export default async function () {
  // 地域サイトのパラメータを取得
  const local_site = useLocalSite();

  // 件数取得
  const { data } = await useTotalCount();

  // 都道府県名からアルファベットのキーを取得
  const pref_key = prefToAlphabet(local_site.prefecture);

  // SP・フッター共通部分
  const baseMenu: MenuLinkItem[] = [
    {
      name: `施工例(${local_site.shop_name})`,
      to: `/sekou/${pref_key}`,
      label: { count: data.sekou, unit: "件以上" },
    },
    {
      name: "プラン事例",
      to: "/plan",
      label: { count: data.plan, unit: "件以上" },
    },
    {
      name: `お客様の声(${local_site.shop_name})`,
      to: "/e_list",
      label: { count: data?.review ?? 0, unit: "件以上" },
    },
    {
      name: "受賞作品",
      to: "/contents.php/contest",
      label: { count: JUSYOU_REKI, unit: "年連続受賞" },
    },
    { name: "最新情報", to: "/news" },
    { name: "ブログ", to: "/BLOG/Finfo" },

    { name: "ファミリー庭園とは", to: "/contents.php/what_famitei" },
    { name: "選ばれる理由", to: "/contents.php/otoku" },
    { name: "工事の流れ", to: "/contents.php/flow" },
    { name: "よくあるご質問", to: "/contents.php/qa" },
    { name: "単価一覧", to: "/contents.php/kouji" },
    { name: "お支払い方法", to: "/contents.php/payment" },
    { name: "アフターフォロー", to: "/contents.php/after" },
    { name: "施工対応エリア", to: "/contents.php/koujiarea" },
    { name: `会社概要(${local_site.shop_name})`, to: "/contents.php/profile" },
    { name: "店舗一覧", to: "/contents.php/shop" },
    { name: "採用情報", to: "/contents.php/recruit" },
    { name: "職人募集", to: "/recruit/contractor" },
    { name: "プライバシーポリシー", to: "/contents.php/privacy" },
    { name: "サイトマップ", to: "/contents.php/sitemap" },
  ];

  // ヘッダーメニュー
  const headerMenu: MenuLinkItem[] = [
    { name: "店舗紹介", to: `/contents.php/p_${local_site.shop_key}` },
    {
      name: "施工エリア",
      to: `/contents.php/koujiarea#${pref_key}`,
    },
    { name: "施工例", to: `/sekou/${pref_key}` },
    { name: "プラン事例", to: "/plan" },
    { name: "お客様の声", to: "/e_list" },
    { name: "店舗一覧", to: "/contents.php/shop" },
    { name: "工事の流れ", to: "/contents.php/flow" },
  ];

  // フッターメニュー
  const footerMenu: MenuLinkItem[] = [
    ...baseMenu,
    ...[
      {
        name: "お問い合せ",
        to: "/inquiry/form",
      },
      {
        name: "Instagram",
        to: "https://www.instagram.com/ex.komikomi/",
        external: true,
      },
      {
        name: "Pinterest",
        to: "https://www.pinterest.jp/ex_komikomi/",
        external: true,
      },
      {
        name: "X(Twitter)",
        to: "https://twitter.com/family_teien",
        external: true,
      },
    ],
  ];

  return {
    baseMenu,
    headerMenu,
    footerMenu,
  };
}
